<template>
    <div class="layout-login">
        <div class="login-wrap">
            <div class="login-content">
                <img src="../assets/img/goldorder_logo.png" alt="골드오더" style="width: 150px;" />
				<!--<h1 style="color: #FFF; font-weight: 800;">골드오더</h1>-->
				<form>
                <div class="login-box">
                    <input type="text" class="form-control id" v-model="userId" placeholder="아이디를 입력해주세요" @keyup.enter="onClickLogin" maxlength="15" autocomplete="off" />
                    <input type="password" class="form-control pw" v-model="userPw" placeholder="비밀번호를 입력해주세요" @keyup.enter="onClickLogin" maxlength="16"  autocomplete="off" />
                    <p class="check">
                        <label class="control checkbox">
                            <input type="checkbox" class="add-contrast" data-role="collar" :value="true" v-model="isSaveUserId" />
                            <span class="control-indicator"></span>
                            <small>아이디 저장</small>
                        </label>
                    </p>
                    <a href="javascript:void(0)" class="btn btn-login" @click="onClickLogin">로그인</a>
                    <br />
                    <div class="text-center"></div>
                </div>
				</form>
            </div>
        </div>
        <div class="login-footer" style="position: absolute; bottom:10px; left:50%; transform:translateX(-50%); color: #FFF; z-index: 9;">
            <div class="footer-copy">© 골드오더</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'login',
    data() {
        return {
            userId: '', // 사용자 아이디
            userPw: '', // 사용자 비밀번호
            isSaveUserId: '', // 사용자 아이디 저장
            clientIP: '', // 클라이언트 IP
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (!_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/main'
            })
        }
        this.userId = this.$cookies.isKey('userId') === false ? '' : this.$cookies.get('userId')
    },
    methods: {
        /**
         * 로그인 함수
         * 
         * @method onClickLogin
         */
        onClickLogin() {
            if (this.isSaveUserId) {
                this.$cookies.set('userId', this.userId)
            }

            if (!this.userId || !this.userPw) {
                alert('아이디 또는 비밀번호를 입력해 주시기 바랍니다.')
                return
            }

			let formData = {
				'id'       : this.userId,
				'password' : this.userPw
			}
            this.$connect('/api/member/login', 'POST', formData).then(bodyData => {
                //console.log("LOGIN:", bodyData);
				if(bodyData.userInfo){  //bodyData.userInfo.role === 'USER'
                    //console.log(bodyData.userInfo)
					//HEADER을 위해 먼저 저장
                	window.sessionStorage.setItem('userInfo', JSON.stringify(bodyData.userInfo))
					this.$router.replace({
						path: '/main'
					})
				}else{
					alert("로그인에 실패했습니다.");
				}
            }).catch(error => {
                alert("로그인에 실패했습니다.\r\n" + error);
                console.log("로그인 처리시 오류 발생:", error);
            })
        }
    }
}
</script>

<style scoped>
@import '../assets/css/login.css';
@import '../assets/css/bootstrap.css';
@import '../assets/css/style.css';
@import '../assets/css/mobile.css';
</style>