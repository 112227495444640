<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" :title="mode === 's' ? '사용자 등록' : '사용자 수정'" :width="1200">
            <template v-slot:body>
                <div class="row pdd-top-15 mrg-btm-15" style="background-color: rgba(255, 218, 185, 0.5);">
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">서비스</label>
                            <div class="col-sm-4">
                                <select class="form-control" v-model="userData.serviceType" placeholder="서비스를 선택하세요." @click="onChangeServiceType()">
                                    <template v-for="(item, index) in serviceTypeList">
                                        <option :value="index" :key="index">{{ item }}</option>
                                    </template>
								</select>
                            </div>
                            <label class="col-sm-2 col-form-label">권한</label>
                            <div class="col-sm-4">
                                <select class="form-control" v-model="userData.role" placeholder="권한을 선택하세요.">
									<option value="ADMIN">관리자</option>
									<option value="MANAGER">상점관리자</option>
									<option value="USER">사용자</option>
								</select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">사용 시작일</label>
                            <div class="col-sm-4">
                                <date-picker 
                                v-model="serviceStartDatetime" 
                                :lang="datePickerLang" 
                                valueType="format"
                                placeholder="시작일을 입력하세요."
                                ></date-picker>
                            </div>
                            <label class="col-sm-2 col-form-label">사용 종료일</label>
                            <div class="col-sm-4">
                                <date-picker 
                                v-model="serviceEndDatetime" 
                                :lang="datePickerLang" 
                                valueType="format"
                                placeholder="종료일을 입력하세요."
                                ></date-picker>
                            </div>
                        </div>

                        <template v-if="mode==='e'">
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">부가서비스&nbsp;<button type="button" class="btn btn-secondary btn-xs" @click="addServiceRow()">추가</button></label>
                                <div class="col-sm-10">
                                    <div v-for="(serviceRow, rowIndex) in serviceRows" :key="rowIndex" style="width: 100%">
                                        <div class="row" :style="rowIndex > 0?'margin-top:10px':''">
                                            <div class="col-sm-4">
                                                <select class="form-control service-select" v-model="serviceRow.parentService" placeholder="부가서비스" 
                                                @change="onChangeAdditionalService(serviceRow, rowIndex)"
                                                >
                                                    <option value="" disabled>부가서비스 선택</option>
                                                    <option v-for="(service, serviceIndex) in parentServiceList" 
                                                    :key="serviceIndex" 
                                                    :value="service"
                                                    :disabled="isServiceDisabled(service, rowIndex, 'parentService')"
                                                    >
                                                    {{ service.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-sm-4" v-if="childrenServiceList[rowIndex] && childrenServiceList[rowIndex].length > 0">
                                                <select class="form-control service-select" v-model="serviceRow.childrenService" placeholder="하위 부가서비스">
                                                    <option value="">하위 부가서비스 선택</option>
                                                    <option v-for="(service, serviceIndex) in childrenServiceList[rowIndex]" 
                                                    :key="serviceIndex"
                                                    :value="service"
                                                    :disabled="isServiceDisabled(service, rowIndex, 'childrenService')"
                                                    >
                                                    {{ service.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-sm-4">
                                                <button type="button" class="btn btn-danger btn-xs" @click="removeServiceRow(rowIndex)">삭제</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row" v-if="lmsServiceState()">
                                <label class="col-sm-2 col-form-label">문자서비스 단가</label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control" placeholder="문자서비스 단가를 입력하세요." v-model="userData.lmsUnitPrice"/>
                                </div>
                            </div>
                        </template>

                        <template v-if="mode==='e'">
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">바로빌 가입</label>
                                <div class="col-sm-4">
                                    <template v-if="userData.memberBarobill && userData.memberBarobill[0]?.id">
                                        <span class="form-control">{{ barobillId }}</span>
                                    </template>
                                    <template v-else>
                                        <button type="button" class="btn btn-primary" @click="onClickBarobill">가입하기</button>
                                    </template>
                                </div>
                            </div>
                        </template>
					</div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">아이디</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control" placeholder="아이디를 입력하세요." v-model="userData.id" :readonly="mode === 'e'" />
                            </div>
                            <template v-if="mode === 's'">
                                <label class="col-sm-2 col-form-label">비밀번호</label>
                                <div class="col-sm-4">
                                    <form autocomplete="off" style="width: 100%;">
                                    <input type="text" name="username" autocomplete="off" style="display:none;">
                                    <input type="password" class="form-control" placeholder="비밀번호를 입력하세요." v-model="userData.password" autocomplete="off" :disabled="mode === 'e'" />
                                    </form>
                                </div>
                            </template>
                        </div>
						<div class="form-group row">
                            <label class="col-sm-2 col-form-label">상호명</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control" placeholder="상호명을 입력하세요." v-model="userData.name" />
                            </div>
                            <label class="col-sm-2 col-form-label">대표자명</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control" placeholder="대표자명을 입력하세요." v-model="userData.ceoName" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">연락처</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control" placeholder="연락처를 입력하세요." v-model="userData.phone" />
                            </div>
                            <label class="col-sm-2 col-form-label">이메일</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control" placeholder="이메일을 입력하세요." v-model="userData.email" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">로고</label>
                            <div class="col-sm-10">
                                <div v-if="userData.logo" style="margin-bottom: 5px;"><img :src="userData.logo"  style="height: 50px;"/></div>
                                <input
                                type="file"
                                ref="logoFile"
                                />
                                <span v-if="userData.logo">
                                    <div class="custom-control custom-control-inline" 
                                    style="padding-top: 10px;">
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="isLogoDel" :checked="isLogoDel" @change="onClickIsLogoDel" />
                                            <label class="custom-control-label" for="isLogoDel">로고를 삭제합니다.</label>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">계좌정보 <button type="button" class="bank_add btn btn-secondary btn-xs" @click="onClickBankAdd">추가</button></label>
                            <div class="col-sm-10">
                                <template v-for="(item, index) in memberAccount">
                                    <div class="form-group row" style="position: relative; margin-left: 0;" :key="index">
                                        <div class="bank_remove" v-if="index > 0">
                                            <template v-if="getBarobillRegistAccountState(item)">
                                                <button type="button" class="bank_del btn btn-secondary btn-xs">연동해제 후 삭제가능</button>
                                            </template>
                                            <template v-else>
                                                <button type="button" @click="onClickBankRemove(index)" class="bank_del btn btn-secondary btn-xs">삭제</button>
                                            </template>
                                        </div>
                                        <label class="col-sm-1 col-form-label">은행</label>
                                        <div class="col-sm-2">
                                            <!--<input type="text" class="form-control" placeholder="은행명을 입력하세요." v-model="item.bankName" />-->
                                            <select class="form-control" placeholder="은행을 선택하세요." v-model="item.bankName" @change="onChangeBank(item)">
                                                <option value="" disabled>은행 선택</option>
                                                <option 
                                                v-for="(item, index) in bankCodeList"
                                                :key="index"
                                                :value="item.name"
                                                >{{ item.name }}</option>
                                            </select>
                                        </div>
                                        <label class="col-sm-1 col-form-label">예금주</label>
                                        <div class="col-sm-2">
                                            <input type="text" class="form-control" placeholder="예금주를 입력하세요." v-model="item.accountName" />
                                        </div>
                                        <label class="col-sm-1 col-form-label">계좌번호</label>
                                        <div class="col-sm-2">
                                            <input type="text" class="form-control" placeholder="계좌번호를 입력하세요." v-model="item.account" />
                                        </div>
                                        <div class="col-sm-2">
                                            <select class="form-control" placeholder="계좌구분 선택" v-model="item.accountType">
                                                <option value="" disabled>계좌구분 선택</option>
                                                <option value="P">개인</option>
                                                <option value="C">법인</option>
                                            </select>
                                        </div>
                                        <template v-if="userData.memberBarobill && userData.memberBarobill[0]?.id && isMemberAdditionalServiceAccount">
                                            <template v-if="getBarobillRegistAccountState(item)">
                                                <div class="col-sm-1">
                                                    <button type="button" class="btn btn-secondary" @click="onClickBarobillTerminateAccount(userData, item, index)">연동 해지</button>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="col-sm-1">
                                                    <button type="button" class="btn btn-secondary" @click="onClickBarobillRegistAccount(userData, item, index)">서비스 연동</button>
                                                    <!--
                                                    <button type="button" class="btn btn-secondary" @click="onClickBarobillTerminateAccount(userData, item, index)">연동 해지</button>
                                                    -->
                                                </div>
                                            </template>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">사업자등록번호</label>
                            <div class="col-sm-3">
                                <input type="number" class="form-control" placeholder="사업자등록번호를 입력하세요." 
                                v-model="userData.businessNumber"
                                :disabled="isBusinessNumber"
                                />
                            </div>
                            <div class="col-sm-1">
                                <button type="button" class="btn btn-secondary" v-if="mode === 's' && !isBusinessNumber" @click="onClickIsBusinessNumber()">중복체크</button>
                                <button type="button" class="btn btn-secondary" v-if="mode === 's' && isBusinessNumber" @click="onClickReBusinessNumber()">다시입력</button>
                            </div>
                            <label class="col-sm-2 col-form-label">통신판매업신고번호</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control" placeholder="통신판매업신고번호를 입력하세요." v-model="userData.mailOrderSalesRegistrationNumber" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">업태</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control" placeholder="업태를 입력하세요." v-model="userData.businessType" />
                            </div>
                            <label class="col-sm-2 col-form-label">업종</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control" placeholder="업종을 입력하세요." v-model="userData.businessItem" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">사업장 주소</label>
                            <div class="col-sm-10">
                                <div style="display: flex;">
                                <input type="text" class="form-control" placeholder="사업장 주소를 입력하세요." v-model="address" />
                                <button type="button" class="btn btn-secondary" @click="onClickDaumPostCode">찾기</button>
                                </div>
                                <br>
                                <input type="text" class="form-control" placeholder="사업장 상세 주소를 입력하세요." v-model="userData.addressDetail" />
                            </div>
                        </div>
                        <div class="form-group row" v-if="mode === 'e'">
                            <label class="col-sm-2 col-form-label">비밀번호 변경여부</label>
                            <div class="col-sm-4">
                                <div class="custom-control custom-control-inline" 
                                style="padding-top: 10px;">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" id="isPasswordChange" :checked="isPasswordChange" @change="onClickIsPasswordChange" />
                                        <label class="custom-control-label" for="isPasswordChange">비밀번호를 변경합니다.</label>
                                    </div>
                                </div>
                            </div>
                            <label class="col-sm-2 col-form-label">변경할 비밀번호</label>
                            <div class="col-sm-4">
                                <form autocomplete="off" style="width: 100%;">
                                <input type="text" name="username" autocomplete="off" style="display:none;">
                                <input type="password" class="form-control" placeholder="변경할 비밀번호를 입력하세요." :disabled="!isPasswordChange" v-model="userData.password" autocomplete="new-password" />
                                </form>
                            </div>
                        </div>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-primary" @click="onClickOK">저장</button>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

    </div>
</template>

<script>
import Modal from '../Modal'
import { mapState } from 'vuex'
import axios from 'axios'
import Datepicker from 'vue2-datepicker'
import { ko } from 'vue2-datepicker/locale/ko'
import "vue2-datepicker/scss/index.scss"

export default {
    components: {
        Modal,
        'date-picker'             : Datepicker,
    },
    data() {
        return {
            datePickerLang       : ko,
            isView               : false,
            mode                 : 's',
            userInfo             : {},
            userData             : {},
            orgUserData          : {},
            memberAccount        : [],
            isPasswordChange     : false,
            serviceTypeList      : {},
            seq                  : 0,
            serviceStartDatetime : "",
            serviceEndDatetime   : "",
            bankCodeList         : [],
            barobillState        : false,
            
            //부가서비스
            services                        : [],
            parentServiceList               : [],
            childrenServiceList             : [],
            serviceRows: [
                { 
                    parentService   : null, 
                    childrenService : null
                }
            ],
            orgServices                      : [],
            orgMemberAdditionalService       : [],
            isMemberAdditionalServiceAccount : false,
            barobillRegistAccountData        : {},
            isMemberAdditionalServiceSeq     : 0,
            isBusinessNumber                 : false,
            address                          : '',
            isLogoDel                        : false,
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
        console.log("this.userInfo:", this.userInfo)
        this.serviceTypeList = this.$store.getters.getServiceType
    },
    mounted() {
        // Load Daum address API script when the component is mounted
        const script = document.createElement('script');
        script.src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
            // Daum 주소 API 스크립트가 로드된 후에 실행할 코드 작성
        };
    },
    computed: {
        ...mapState(['domainUrl']),
        ...mapState(['imageUrl'])
    },
    methods: {
        onShow(payload) {
            this.userData      = {}
            this.memberAccount = []
            this.mode          = 's'
            this.seq           = 0
            this.bankCodeList  = []
            this.barobillId    = ''
            this.barobillState = false

            this.serviceRows = [
                { 
                    parentService  : null, 
                    childrenService: null
                }
            ]
            this.parentServiceList                = []
            this.childrenServiceList              = []
            this.orgServices                      = []
            this.orgMemberAdditionalService       = []
            this.isMemberAdditionalServiceAccount = false
            this.isMemberAdditionalServiceSeq     = 0
            this.isBusinessNumber                 = false
            this.address                          = ""
            this.isLogoDel                        = false

            this.getBankCodeList()

			if(payload && payload.seq){
                this.mode = 'e'
                this.seq = payload.seq
                this.isBusinessNumber = true
                this.getTMData()
			}else{
                this.memberAccount = [{
                    bankName : '',
                    accountName: '',
                    account: ''
                }]
            }

            this.$refs.modal.onShow()
        },

        /**
         * 데이터를 가져오는 함수
         *
         * @method getShopSetting
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
        async getTMData() {
            this.$connect(
				`/api/member/${this.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.userData = bodyData.list
                //console.log(this.userData)
                this.address  = this.userData.address
                this.orgUserData                      = JSON.parse(JSON.stringify(bodyData.list))
                this.memberAccount                    = JSON.parse(JSON.stringify(this.userData.memberAccount))
                this.serviceStartDatetime             = this.userData.serviceStartDatetime?.substr(0, 10)
                this.serviceEndDatetime               = this.userData.serviceEndDatetime?.substr(0, 10)
                this.barobillId                       = this.userData.memberBarobill[0]?.id?this.userData.memberBarobill[0]?.id:''
                this.orgMemberAdditionalService       = JSON.parse(JSON.stringify(this.orgUserData.memberAdditionalService))
                
                const depositService = this.orgMemberAdditionalService.find(service => service.name.includes('입금'));
                this.isMemberAdditionalServiceAccount = depositService?depositService.seq : false
                this.isMemberAdditionalServiceSeq     = depositService?depositService.serviceSeq : 0

                this.getAdditionalService()
                this.getBarobillRegistAccount()

                //v-model을 맞추기 위해서 재설정한다.
                this.serviceRows = []
                let memberAdditionalService = JSON.parse(JSON.stringify(this.userData.memberAdditionalService))
                if(memberAdditionalService.length > 0){
                    memberAdditionalService.forEach(item => {
                        delete item.seq
                        delete item.useYN
                        delete item.startDate
                        delete item.endDate
                        delete item.createDatetime
                        delete item.modifyDatetime
                        delete item.createUserId
                        delete item.modifyUserId
                    })
                    memberAdditionalService.filter(item => item.parentSeq === 0).forEach(parentService => {
                        // parentService 설정
                        const row = {
                            parentService  : parentService,
                            childrenService: null
                        };

                        // parentService와 매칭되는 selectedAdditionalService 찾기
                        const childService = memberAdditionalService.find(item => item.parentSeq === parentService.serviceSeq)
                        if (childService) {
                            row.childrenService = childService
                        }

                        // serviceRows 배열에 추가
                        this.serviceRows.push(row);
                    });
                }

            })
            .catch(error => {
                console.log("error", error);
            })
        },

        onClickIsPasswordChange(){
            this.isPasswordChange = !this.isPasswordChange
        },

        onClickIsLogoDel(){
            this.isLogoDel = !this.isLogoDel
        },

        
        onChangeServiceType(){
            this.getAdditionalService()
        },

        getAdditionalService(){
            this.$connect('/api/additionalService/list/1/1000', 'GET')
            .then(bodyData => {
                this.orgServices = JSON.parse(JSON.stringify(bodyData.list.content))
                this.services    = bodyData.list.content;
                
                this.services.forEach(item => {
                    item.serviceSeq = item.seq
                    delete item.seq
                    delete item.createDatetime
                    delete item.modifyDatetime
                    delete item.createUserId
                    delete item.modifyUserId
                })
                this.parentServiceList = this.services.filter(service => service.name && service.parentSeq === 0 && service.serviceType === this.userData.serviceType);

                // childrenServiceList 초기화
                if (this.mode === 'e') {
                    this.serviceRows.forEach((serviceRow, rowIndex) => {
                        const selectedParentService = serviceRow.parentService;
                        if (selectedParentService) {
                            this.$set(this.childrenServiceList, rowIndex, this.services.filter(service => service.parentSeq === selectedParentService.serviceSeq));
                        }
                    });
                }
            })
            .catch(error => {
                console.log("error", error);
            });
        },

        onChangeAdditionalService(serviceRow, rowIndex) {
            const selectedParentService = serviceRow.parentService;
            this.$set(this.childrenServiceList, rowIndex, this.services.filter(service => service.parentSeq === selectedParentService.serviceSeq));

            if(selectedParentService.name.includes('문자')){
                this.userData.lmsUnitPrice = selectedParentService.unitPrice
            }

        },

        addServiceRow() {
            this.serviceRows.push({ parentService: null, childrenService: null });
        },

        removeServiceRow(rowIndex) {
            this.serviceRows.splice(rowIndex, 1);
            this.childrenServiceList.splice(rowIndex, 1);  // 추가 서비스 리스트도 제거
        },

        isServiceDisabled(service, currentIndex, type) {
            for (let i = 0; i < this.serviceRows.length; i++) {
                if (i !== currentIndex && this.serviceRows[i][type] && this.serviceRows[i][type].serviceSeq === service.serviceSeq) {
                    return true;
                }
            }
            return false;
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseContentSave', {
                isUpdate: this.isUpdate
            })
        },

        onClickOK() {
            this.onClickSave()
        },

		async onClickSave() {
            if(!this.isBusinessNumber){
                alert("사업자번호를 중복체크 해주세요.")
                return false
            }

            if(
                !this.userData.serviceType || 
                !this.userData.name || 
                !this.userData.id || 
                !this.userData.ceoName || 
                !this.userData.businessNumber || 
                !this.userData.businessType || 
                !this.userData.businessItem || 
                !this.address || 
                !this.userData.addressDetail || 
                !this.userData.phone || 
                !this.userData.email ||
                !this.serviceStartDatetime || 
                !this.serviceEndDatetime
            ){
                alert("필요한 사용자 정보를 입력해주세요.")
                return false
            }

            if(this.userData.serviceStartDatetime > this.userData.serviceEndDatetime){
                alert("종료일은 시작일보다 늦어야합니다.")
                return false
            }

            try {
                // 파일 업로드
                let uploadFiles = this.$refs.logoFile.files;
                if(uploadFiles[0]){
                    let formData = new FormData();
                    formData.append("file", uploadFiles[0]);

                    const response = await axios.post(`${this.domainUrl}/api/file/upload`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${this.userInfo.userToken}`
                        }
                    });
                    
                    // 업로드한 파일의 URL을 modalData.logo에 설정
                    this.userData.logo = this.imageUrl + '/upload/' + response.data;
                }

                // 필요없는 필드 제거
                delete this.userData.accountNonExpired
                delete this.userData.accountNonLocked
                delete this.userData.createDatetime
                delete this.userData.modifyDatetime
                delete this.userData.userToken
                delete this.userData.authorities
                delete this.userData.credentialsNonExpired

                if(this.mode === 's'){
                    this.userData.enabled               = true
                    this.userData.createUserId          = this.userInfo.id
                }else{
                    this.userData.modifyUserId          = this.userInfo.id
                }
                this.userData.memberAccount        = this.memberAccount
                this.userData.serviceStartDatetime = this.serviceStartDatetime + 'T00:00:01'
                this.userData.serviceEndDatetime   = this.serviceEndDatetime + 'T23:59:59'

                //부가서비스 정리 - messageUnitPrice
                const updatedServices = this.serviceRows.reduce((acc, row) => {
                    const services = [row.parentService, row.childrenService].filter(Boolean);

                    services.forEach(service => {
                        const matchedService = this.orgMemberAdditionalService.find(orgService => orgService.serviceSeq === service.serviceSeq);
                        acc.push(matchedService ? matchedService : service);
                    });

                    return acc;
                }, []);

                //부가서비스 신규값 세팅
                updatedServices.forEach(service => {
                    this.$set(service, 'useYN', 1);
                    if(service.name.includes('문자')){ 
                        this.$set(service, 'unitPrice', this.userData.lmsUnitPrice);
                    }
                })

                this.userData.memberAdditionalService = updatedServices
                this.userData.address = this.address

                if(this.isLogoDel){
                    this.userData.logo = ""
                }

                this.$connect(
                    `/api/member`,
                    'POST', this.userData
                )
                .then(bodyData => {
                    this.isUpdate = true
                    this.onClickClose()
                })
            } catch (error) {
                console.error("Error:", error);
                alert("설정을 저장하는 중 오류가 발생했습니다.");
            }
		},

        async onClickLogoFileUpload(){
            try {
                let uploadFiles = this.$refs.logoFile.files;
                let formData = new FormData();
                formData.append("file", uploadFiles[0]);

                const response = await axios.post(`${this.domainUrl}/api/file/upload`, formData, {
                    headers: {
                        'Content-Type' : 'multipart/form-data',
                        'Authorization': `Bearer ${this.userInfo.userToken}`
                    }
                });

                this.this.userData = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        onClickBankAdd(){
            this.memberAccount.push({
                bankName : '',
                accountName: '',
                account: ''
            })
        },

        onClickBankRemove(index){
            this.memberAccount.splice(index, 1);
        },

        onClickBarobillRegistAccount(userData, item, index){
            if(
                !item.account || 
                !item.accountName || 
                !item.accountType || 
                !item.bankName || 
                !item.seq
            ){
                alert("계좌 정보의 전체 입력 후 연동해주세요")
                return false
            }

            if(!item.accountType){
                alert("연동을 위한 정보를 입력해주세요.")
                return false
            }

            if(confirm("바로빌 입금 확인 위한 계좌 연동은\r계좌당 1만원/1개월의 추가비용이 발생합니다.\r계속 진행하시겠습니까?")){
                this.onClickSave()
                this.$refs.modalBankServiceSave.onShow({
                    "userData"             : userData,
                    "item"                 : item,
                    "additionalServiceSeq" : this.isMemberAdditionalServiceSeq
                })
            }

        },

        onCloseBarobillRegistAccount(payload){
            if(payload.isUpdate){
                this.getTMData()
            }
        },

        onClickBarobillTerminateAccount(userData, item, index){
            if(
                !item.account || 
                !item.accountName || 
                !item.accountType || 
                !item.bankName || 
                !item.seq
            ){
                alert("계좌 정보의 전체 입력 후 연동 해지해주세요")
                return false
            }

            if(confirm("바로빌 입금 확인 위한 계좌 연동을 해지하시겠습니까?")){
                this.onClickSave()
                this.$refs.modalBankServiceDel.onShow({
                    "userData"             : userData,
                    "item"                 : item,
                    "additionalServiceSeq" : this.isMemberAdditionalServiceSeq
                })
            }

        },

        /**
         * 바로빌과 연동해 사용하는 은행코드
         *
         * @method getBankCodeList
         */
         getBankCodeList(){
            this.$connect(
				`/api/bankcode/listAll`,
                'GET'
            )
            .then(bodyData => {
                this.bankCodeList = bodyData.list
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        /**
         * 은행코드 넣기
         *
         * @method onChangeBank
         */
        onChangeBank(item){
            const selectedBank = this.bankCodeList.find(bank => bank.name === item.bankName);
            if (selectedBank) {
                item.bankCode = selectedBank.code;
            } else {
                item.bankCode = '';
            }
        },

        /**
         * 바로빌과 연동해 사용하는 은행코드
         *
         * @method getBankCodeList
         */
         getBarobillRegistAccount(){
            this.$connect(
				`/api/member/getBarobillRegistAccount/${this.userData.id}`,
                'GET'
            )
            .then(bodyData => {
                this.barobillRegistAccountData = bodyData.list
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        getBarobillRegistAccountState(item){
            
            if(this.barobillRegistAccountData && this.barobillRegistAccountData.length > 0){
                const accountData = this.barobillRegistAccountData.find(
                    data => data.account === item.account
                );
                if (accountData && accountData.isRegist) {
                    return accountData.isRegist === 'R' ? true : false;
                }
                return false;
            }else{
                return false;
            }
        },
        
        /**
         * 바로빌 가입
         *
         * @method onClickBarobill
         */
        onClickBarobill(){
            if(confirm("바로빌 가입을 진행하시겠습니까?")){
                if(
                    !this.userData.name || 
                    !this.userData.ceoName || 
                    !this.userData.businessNumber || 
                    !this.userData.businessType || 
                    !this.userData.businessItem || 
                    !this.address || 
                    !this.userData.addressDetail || 
                    !this.userData.phone || 
                    !this.userData.email
                ){
                    alert("바로빌 가입을 위한 정보가 입력되지 않았습니다.\r\n[상호명] [대표자명] [사업자등록번호] [업종] [업태]\r\n[사업장 주소] [연락처] [이메일]\r\n위의 정보를 저장 후 바로빌 아이디를 가입해주세요.")
                    return
                }
                this.onClickSave()
                this.$connect(
                    `/api/member/barobillJoin/${this.userData.id}`,  //회원의 아이디를 보냄
                    'GET'
                )
                .then(bodyData => {
                    if(bodyData){
                        alert("바로빌에 가입되었습니다.")
                        this.getTMData()
                    }
                }).catch(error => {
                    console.log("error", error);
                })
            }
        },

        /**
         * 사업자등록번호 중복 체크
         *
         * @method onClickIsBusinessNumber
         */
        onClickIsBusinessNumber(){
            this.isBusinessNumber = false

            if(!this.userData.businessNumber){
                alert("사업자등록번호 입력 후 중복체크해주세요.")
                return
            }

            const sanitizedNumber = this.userData.businessNumber.replace(/[^0-9]/g, '');
            if (!this.isValidBusinessNumber(sanitizedNumber)) {
                alert("유효한 사업자등록번호를 입력해주세요.");
                return
            }

            this.$connect(
                    `/api/member/businessNumber/${sanitizedNumber}`,  //회원의 아이디를 보냄
                    'GET'
                )
                .then(bodyData => {
                    if(bodyData.list.length > 0){
                        if(confirm("현재 사업자등록번호로 이미 등록되어 가입이 불가능합니다.\r\r사업자등록번호를 다시 입력하시려면 [취소]를 눌러주세요.\r사업자등록번호가 같은 사용자를 수정하시려면 [확인]을 눌러주세요.")){
                            this.mode = 'e'
                            this.seq = bodyData.list[0].seq
                            this.getTMData()
                        }
                    }else{
                        this.isBusinessNumber = true
                    }
                }).catch(error => {
                    console.log("error", error);
                })
        },

        /**
         * 사업자등록번호 체크
         *
         * @method isValidBusinessNumber
         */
        isValidBusinessNumber(businessNumber) {
            const number = businessNumber.replace(/-/g, '');
            if (number.length !== 10) {
                return false;
            }

            const weight = [1, 3, 7, 1, 3, 7, 1, 3, 5];
            let sum = 0;

            for (let i = 0; i < 9; i++) {
                sum += weight[i] * parseInt(number[i]);
            }

            sum += parseInt((weight[8] * parseInt(number[8])) / 10);
            const remainder = (10 - (sum % 10)) % 10;

            return remainder === parseInt(number[9]);
        },

        onClickReBusinessNumber(){
            this.isBusinessNumber = false
            this.userData.businessNumber = ''
        },

        /**
         * 주소검색
         *
         * @method onClickDaumPostCode
         */
        onClickDaumPostCode(){
			const self = this
            new window.daum.Postcode({
                oncomplete: data => {
					//console.log(data)
                    this.address = data.address
					this.$forceUpdate()
                }
            }).open()
        },

        /**
         * 문자서비스 있는지 
         *
         * @method lmsServiceState
         */
        lmsServiceState(){
            return this.serviceRows.some(service => 
                (service.parentService && service.parentService.name === "문자서비스")
            )
        }
    },
}
</script>
<style scoped>
.service-select option:disabled {
    text-decoration: line-through;
    color: #aaa;
    background-color: #f5f5f5;
}
</style>