<template>
    <div>
        <div class="boxwrap">
            <div class="searchwrap">
                <form>
                    <div class="form-row align-items-center">
                        <label class="col-form-label">검색어</label>
                        <select-box
                            ref="selectBox"
                            :selectBoxs="[
                                { name: '상품명', value: 'NAME' },
                            ]"
                            :default-select-box-value="searchKey"
                            @onChangeSearchBox="setSelectBox"
                            :key="viewKey + 1"
                        ></select-box>
                        <!--{ name: '배송방법', value: 'DELIVERY' }-->
                        <div class="col-auto ssb-search w-25">
                            <input v-model="searchText" class="form-control" type="text" placeholder="검색어를 입력하세요" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-12 text-center">
                        <button type="button" class="btn btn-primary btn-search" @click="onClickSearch">검색</button>&nbsp;
                        <button type="button" class="btn btn-secondary btn-search" @click="onClickClear">전체검색</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="title">
                <div class="option-btn mrg-left-10">
					<select class="form-control" v-model="tableListRow" @change="onChangeListRow">
                        <option value="10">10개씩 보기</option>
						<option value="20">20개씩 보기</option>
						<option value="40">40개씩 보기</option>
						<option value="80">80개씩 보기</option>
						<option value="100">100개씩 보기</option>
					</select>
				</div>
                <div class="option-btn mrg-left-10">
                    <a href="javascript:void(0)" class="btn btn-primary btn-sm" @click="onClickModalContentSave">상품 등록</a>
                </div>
                <h3 class="text-left">목록 (총
                    <strong class="point">{{ totalCount }}</strong>건)
                </h3>
            </div>
            <table class="table table-bordered">
                <thead class="thead-contact">
                    <tr>
                        <th scope="col" style="width:120px">일련번호</th>
                        <th scope="col">상품명</th>
                        <th scope="col" style="width:120px">단가</th>
                        <th scope="col" style="width:120px">주문단위</th>
                        <!--
                        <th scope="col" style="width:120px">배송방법</th>
                        <th scope="col" style="width:120px">배송구분</th>
                        <th scope="col" style="width:120px">배송비</th>
                        -->
                        <th scope="col" style="width:120px">재고</th>
                        <th scope="col" style="width:120px">관리</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item, index) in tableList">
                        <tr :key="index" class="link">
                            <td @click="onClickModalContentDetail(item)">{{ item.seq }}</td>
                            <td class="text-left" @click="onClickModalContentDetail(item)">{{ item.name }}</td>
							<td @click="onClickModalContentDetail(item)">{{ onNumberFormat(item.unitPrice) }}원</td>
                            <td @click="onClickModalContentDetail(item)">{{ item.unitQuantity }}</td>
                            <!--
                            <td @click="onClickModalContentDetail(item)">{{ item.deliveryType }}</td>
                            <td @click="onClickModalContentDetail(item)">{{ item.deliveryPayType }}</td>
                            <td @click="onClickModalContentDetail(item)">{{ onNumberFormat(item.deliveryFee) }}</td>
                            -->
                            <td @click="onClickModalContentDetail(item)">{{ (item.isUseStock==='1' || item.isUseStock===1)?item.goldCarriageAvailableStock:"사용안함" }}</td>
                            <td>
                                <button type="button" class="btn btn-secondary btn-xs" @click="onClickModalContentSave(item)">수정</button>
                                &nbsp;<button type="button" class="btn btn-secondary btn-xs" @click="onClickModalContentDelete(item)">삭제</button></td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <paging-nations 
			ref="pagingNations" 
			:total-content-count="totalCount" 
			:row-per-page=parseInt(tableListRow)
			v-on:onClickPage="getTMPageNum" 
			:key="pagingViewKey" 
			v-show="totalCount !== 0"
			></paging-nations>
        </div>

		<!-- 상세보기 -->
        <modal-content-detail 
            ref="modalContentDetail" 
            @onCloseContentDetail="onCloseContentDetail"
		></modal-content-detail>

        <!--등록/수정-->
		<modal-content-save 
            ref="modalContentSave" 
            @onCloseContentSave="onCloseContentSave"
		></modal-content-save>

        <!--삭제-->
		<modal-content-delete 
            ref="modalContentDelete" 
            @onCloseContentDelete="onCloseContentDelete"
		></modal-content-delete>

    </div>
</template>
  
<script>
import { tableMixin } from '../../table-mixin'
import * as DateUtils from '../../utils/date-utils'
import * as EtcUtils from '../../utils/etc-utils'

import Pagingnations from '../../components/Pagingnations'
import MContentDetail from '../../modal/Product/MContentDetail'
import MContentSave from '../../modal/Product/MContentSave'
import MContentDelete from '../../modal/Product/MContentDelete'

import SelectBox from '../../components/SelectBox'

export default {
    name: 'orderList',
    mixins: [tableMixin],
    components: {
        'select-box'             : SelectBox,
        'paging-nations'         : Pagingnations,
		'modal-content-detail'   : MContentDetail,
        'modal-content-save'     : MContentSave,
        'modal-content-delete'   : MContentDelete,
    },
    data() {
        return {
            searchKey   : 'NAME',
            searchText   : '',
            totalCount   : 0,
            tableList    : [],
            tableListRow : 10,
            userInfo     : {}
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
        //console.log("userInfo:", this.userInfo)
    },
    mounted() {
        //목록 LOAD
        this.getTMData();
    },

    methods: {
        /**
         * 데이터를 가져오는 함수
         *
         * @method getTMData
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
        getTMData() {
            this.tableList = [];
            this.totalCount = 0;

            const channel = this.$store.getters.getChannel
            const param = {
                "channel"          : channel,
                "startDate"        : "",
                "endDate"          : "",
                "searchType"       : this.searchText?this.searchKey:"",
                "searchText"       : this.searchText,
                "orderingParam"    : "",
                "orderingAscending": ""
            }
            console.log("product param:", JSON.stringify(param))
            this.$connect(
				`/api/product/list/${this.searchData.currentPage}/${this.tableListRow}`,
                'POST', param
            )
            .then(bodyData => {
                //console.log("bodyData.list.content:", bodyData.list.content);

                this.tableList  = bodyData.list.content;
                this.totalCount = bodyData.totalCount;
            })
            .catch(error => {
                console.log("error", error);
            })
        },

		/**
         * 상세보기 모달 함수
         *
         * @method onModalContentDetail
         * @param {Object} item 선택한 아이템 정보
         */
		 onClickModalContentDetail(item) {
            this.$refs.modalContentDetail.onShow(item)
        },

        /**
         * 상세보기 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentDetail
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
        onCloseContentDetail(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },

		/**
         * 등록/수정 모달 함수
         *
         * @method onModalContentDetail
         * @param {Object} item 선택한 아이템 정보
         */
		 onClickModalContentSave(item) {
            this.$refs.modalContentSave.onShow(item)
        },

        /**
         * 등록/수정 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentDetail
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
        onCloseContentSave(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },

        /**
         * 상태 변경 함수
         *
         * @method onChangeSalesState
         * @param {Object} item 선택한 아이템 정보
         */
		onChangeSalesState(item){

		},

        /**
         * 삭제 모달 함수
         *
         * @method onClickModalContentDelete
         * @param {Object} item 선택한 아이템 정보
         */
        onClickModalContentDelete(item){
            this.$refs.modalContentDelete.onShow(item)
        },

        /**
         * 삭제 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentSave
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
         onCloseContentDelete(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },

        /**
         * 목록 보여지는 ROW 수 변경 함수
         *
         * @method onChangeListRow
         * @param 
         */
         onChangeListRow(){
            this.getTMData()
        },

        /**
         * 숫자를 천 단위로 구분하여 포맷팅
         *
         * @method onNumberFormat
         * @param {Number} num 포맷팅할 숫자
         * @return {String} result 천 단위로 구분된 문자열
         */
         onNumberFormat(num) {
            return num?EtcUtils.numberFormat(num):0
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
         onFormatDateYmd(dateString) {
            return DateUtils.formatDateYmd(dateString)
        },

        /**
         * 셀렉트 박스 값을 설정하는 함수
         *
         * @method setSelectBox
         * @param {Object} payload 셀렉트 박스 관련 데이터를 담은 객체
         */
         setSelectBox(payload){
            this.searchKey  = payload.selectValue
            this.searchText = ''
        },

        /**
         * 검색 함수
         *
         * @method onClickSearch
         */
         onClickSearch() {
            this.getTMData();
            this.$refs.pagingNations.currentPage = 1;
        },

        /**
         * 초기화(전체검색) 함수
         *
         * @method onClickClear
         */
        onClickClear() {
            this.searchKey  = 'NAME'
            this.searchText = ''
            this.$refs.selectBox.setSelectedValue(this.searchKey);

            this.totalCount = 0
            this.tableList = []
            this.getTMData()
        },
    }
}
</script>