<template>
    <div>
        <div class="boxwrap">
            <div class="title">
                <h3 class="text-left">네이버밴드</h3>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">네이버밴드 로그인</label>
                        <div class="col-sm-10">
                            <!--
                            <template v-if="!isLogin">
                            -->
                                <a href="javascript:void(0)" @click="onClickLoginNaverBand()" class="link">
                                    <span style="padding: 5px 10px; border: 2px solid #03d686; border-radius: 25px;">
                                        <img src="https://i.namu.wiki/i/4WZ0QQN0sZPfyDiiwkzt6N-ja1mYjTWcnkhx9TR5t6-_VRYaUAIXmGBaUCfrAJtoomRuBNRdNAzAqaqT7sDXUQ.svg" style="width: 80px;">
                                        <span style="border: 0; margin-left: 5px; font-weight: 800;">네이버 밴드 로그인</span>
                                    </span>
                                </a>
                            <!--
                            </template>
                            <template v-else>
                                <div style="margin-top: 10px;">* 네이버 밴드 연동중입니다.</div>
                            </template>
                            -->
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">네이버밴드 목록</label>
                        <div class="col-sm-10">
                            <table class="table table-bordered">
                                <thead class="thead-contact">
                                    <tr>
                                        <th scope="col" class="text-center">이미지</th>
                                        <th scope="col">밴드명</th>
                                        <th scope="col" class="text-center">연동상태</th>
                                        <th scope="col" class="text-center">연동</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item, index) in realBandList">
                                        <tr :key="index">
                                            <td class="text-center"><img :src="item.cover" style="height: 40px;"/></td>
                                            <td>{{ item.name }}</td>
                                            <td class="text-center">
                                                <template v-if="onCheckBandSave(item)">
                                                    연동중
                                                </template>
                                                <template v-else>
                                                    -
                                                </template>
                                            </td>
                                            <td class="text-center">
                                                <template v-if="onCheckBandSave(item)">
                                                    <button type="button" class="btn btn-secondary btn-xs" @click="onClickBandDel(item)">해지하기</button>
                                                </template>
                                                <template v-else>
                                                    <button type="button" class="btn btn-secondary btn-xs" @click="onClickBandSave(item)">연동하기</button>
                                                </template>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { tableMixin } from '../../table-mixin'
import axios from 'axios'
import * as DateUtils from '../../utils/date-utils'

export default {
    name: 'naverBandSetting',
    mixins: [tableMixin],
    components: {
    },
    data() {
        return {
            userInfo             : {},
            naverBandClientId    : '',
            naverBandRedirectUri : '',
            accessInfo           : {},
            realBandList         : [],
            bandList             : [],
            isLogin              : false,
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
    },
    mounted() {
        this.naverBandClientId    = this.$store.getters.getNaverBandClientId()
        this.naverBandRedirectUri = this.$store.getters.getNaverBandRedirectUri()
        this.getBandAccessInfo()
        this.getBandList()
    },

    methods: {
        onClickLoginNaverBand(){
            //운영
            const clientId    = '431868360';
            const redirectUri = encodeURIComponent('http://goldcarriageshop.kr/naverBand/naverBand.php')

            //테스트서버
            //const clientId    = '434116150';
            //const redirectUri = encodeURIComponent('http://1.241.84.248:8080/naverBand/naverBand.php')

            const authUrl = `https://auth.band.us/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`
            window.location.href = authUrl;
        },

        getBandAccessInfo(){
            console.log(`/api/band/accessInfo/id/${this.userInfo.id}`)

            this.$connect(
				`/api/band/accessInfo/id/${this.userInfo.id}`,
                'GET'
            )
            .then(bodyData => {
                this.accessInfo = bodyData.list[0]

                if(this.accessInfo){
                    fetch(`https://openapi.band.us/v2.1/bands?access_token=${this.accessInfo.accessToken}`, {
                        method: 'GET',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }
                    )
                    .then(response => response.json())
                    .then(data => {
                        if(data.result_code === 1){
                            this.realBandList = data.result_data.bands
                            this.isLogin = true
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
                }

            })
            .catch(error => {
                console.log("error", error);
            })
        },

        getBandList(){
            this.$connect(
				`/api/band/band/list/${this.userInfo.id}`,
                'GET'
            )
            .then(bodyData => {
                this.bandList = bodyData.list
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        onCheckBandSave(item){
            return this.bandList.some(obj => obj.bandKey === item.band_key);
        },

        onClickBandSave(item){
            const param = {
                "memberId"    : this.userInfo.id,
                "accessToken" : this.accessInfo.accessToken,
                "name"        : item.name,
                "cover"       : item.cover,
                "bandKey"     : item.band_key,
                "createUserId": this.userInfo.id,
                "modifyUserId": this.userInfo.id,
            }
            this.$connect(
				`/api/band/band/save`,
                'POST', param
            )
            .then(bodyData => {
                this.getBandList()
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        onClickBandDel(item){
            const foundItem = this.bandList.find(obj => obj.bandKey === item.band_key);
            if(foundItem && foundItem.seq){
                this.$connect(
                    `/api/band/band/${foundItem.seq}`,
                    'DELETE'
                )
                .then(bodyData => {
                    this.getBandList()
                })
                .catch(error => {
                    console.log("error", error);
                })
            }
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
         onFormatDateYmd(dateString) {
            return DateUtils.formatDateYmd(dateString).substr(0, 10)
        },
    }
}
</script>